import axios from "axios";

// TODO: we should remove this once all action creators are migrated to axiosconfig.js
axios.defaults.baseURL = "/";
axios.defaults.headers["Accept"] = "application/json";
axios.interceptors.request.use((config) => {
    config.headers["X-CSRF-TOKEN"] = document.querySelector('meta[name="csrf-token"]').getAttribute("content");
    return config;
});
axios.interceptors.response.use(
    (response) => {
        replaceCsrfToken(response);
        return response;
    },
    (error) => {
        replaceCsrfToken(error.response);
        if (error.response && 401 === error.response.status && !window.location.pathname.startsWith("/login")) {
            window.location = "/login";
        }
        return Promise.reject(error);
    }
);

function replaceCsrfToken(response) {
    if (response && response.headers["x-csrf-token"]) {
        document.querySelector('meta[name="csrf-token"]').setAttribute("content", response.headers["x-csrf-token"]);
    }
}
